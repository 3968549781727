import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue';
import WorkSection from '@/components/WorkSection.vue';
import SkillSection from '@/components/SkillSection.vue';
import AchievementSection from '@/components/AchievementSection.vue';
// import EducationSection from '@/components/EducationSection.vue';
import ProjectsSection from '@/components/ProjectsSection.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Work',
    component: WorkSection
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsSection
  },
  {
    path: '/skills',
    name: 'Skills',
    component: SkillSection
  },
  // {
  //   path: '/education',
  //   name: 'Education',
  //   component: EducationSection
  // },
  {
    path: '/achievements',
    name: 'Achievements',
    component: AchievementSection
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
