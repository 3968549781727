<template>
    <div class="d-flex justify-content-center d-md-none my-4">
        <span class="section-title">PROJECTS</span>
    </div>
    <div class="projects-wrapper">
        <div v-for="project in projects" :key="project.id" class="project-card-wrapper">
            <div>
                <div>
                    <img :src="getThumbnailPath(project.thumbnail)" alt="" class="thumbnail">
                </div>
                <div class="mt-3 project-about">{{ project.about }}</div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="project-title">{{ project.title }}</div>
                    <div class="d-flex justify-content-center gap-3">
                        <a :href="project.links.liveDemo" target="_blank" v-if="project.links.liveDemo"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                        <a :href="project.links.github" target="_blank" v-if="project.links.github"><i class="fa-brands fa-github"></i></a>
                        <a :href="project.links.youtube" target="_blank" v-if="project.links.youtube"><i class="fa-brands fa-youtube"></i></a>
                    </div>
                </div>
                
                <div class="project-year">{{ project.year }}</div>
                <div class="project-desc">{{ project.desc }}</div>
                <div class=" d-flex justify-content-start gap-3 mt-3 align-items-center flex-wrap">
                    <div class="project-tech" v-for="tech of project.techUsed" :key="tech">
                        <img :src="getLogoPath(tech)" alt="" width="30" height="30">
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
</template>

<script setup>

const projects = [
    {
        id: 0,
        thumbnail: "tradetactix.png",
        title: "TradeTactix",
        about: "Stock Analysis Platform",
        techUsed: ["Vue", "MongoDB", "Redis", "Node", "Express", "D3"],
        desc: "A Vue.js web app for technical analysis of Indian stocks, with a Node.js and Express backend. Redis caches historical data, MongoDB stores company info, and SMA/RSI charts are visualized using Observablehq Plot.",
        year: 2024,
        links: {
            github: "https://github.com/jayyprajapati/stocks-analysis",
            liveDemo: "",
            youtube: "https://youtu.be/LFJjjmQ198E?si=yKVysjMLZvNLCACk",
        }
    },
    {
        id: 1,
        thumbnail: "cloudnotes.png",
        title: "CloudNotes",
        about: "Note Management Application",
        techUsed: ["React", "MongoDB", "Redux", "Node", "Express"],
        desc: 'A note taking application which requires user credentials and allow users to add new note, edit/delete existing ones and search specific notes.',
        year: 2022,
        links: {
            github: "https://github.com/jayyprajapati/cloudnotes",
            liveDemo: "https://cloudnotes-ftr2.onrender.com/",
            youtube: "https://youtu.be/Qy5VH2wzzEg?si=y7kJ4re2hzt7RGFl",
        }
    },
    {
        id: 2,
        title: "NewsBee",
        about: "News Platform",
        thumbnail: "newsbees.png",
        techUsed: ["React"],
        desc: 'A news fetch application which renders all the latest news on a single page with infinite scrolling. It also includes category specific news filters.',
        year: 2022,
        links: {
            github: "https://github.com/jayyprajapati/React-NewsBee",
            liveDemo: "",
            youtube: ""
        }
    },
    {
        id: 3,
        thumbnail: "tinybasket.png",
        title: "TinyBasket",
        about: "E-commerce Platform",
        techUsed: ["Ejs", "MongoDB", "Node", "Express"],
        desc: 'Grocery E-commerce website where logged in user can explore/search the products in categorized manner. User can add products to cart and complete the payment using checkout option.',
        year: 2020,
        links: {
            github: "https://github.com/jayyprajapati/Online-Grocery-Store",
            liveDemo: "",
            youtube: "https://youtu.be/z5sIunlf5Hs?si=f7cMlCtH299TChyv"
        }
    },
]
function getLogoPath(logo) {
    return require(`@/assets/svg/skills/${logo}.svg`);
}

function getThumbnailPath(img) {
    return require(`@/assets/images/thumbnails/${img}`);
}
</script>

<style scoped lang="scss">

.project-card-wrapper {
    max-width: 350px;
    height: 450px;
    border-radius: 10px;
    // background: var(--background-dark-color);
    border: 1px solid var(--border-light-grey-color);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    // color: #fff;
    padding: 20px;
}

.thumbnail {
    width: 100%;
    height: 140px;
    border-radius: 5px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.project-title {
    font-weight: 600;
    // color: var(--highlight-text-color);
    color: var(--highlight-text-color);
    font-size: 20px;
    text-align: left;
    padding-block: 5px;

    
}

i {
    &:hover {
        color: var(--highlight-text-color)
    }
}

.project-desc {
    font-size: 12px;
    text-align: justify;
    // font-style: italic;
    font-weight: 500;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100px;
}

.project-year {
    border: 1px solid var(--border-light-grey-color);
    border-radius: 50px;
    padding-block: 3px;
    padding-inline: 15px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    margin-bottom: 10px;
}

.project-about {
    font-size: 12px;
    font-weight: 600;
    color: #5c667a;
    text-align: left;
}
</style>