<template>
    <div class="d-flex justify-content-center d-sm-none my-4">
        <span class="section-title">ACHIEVEMENTS</span>
    </div>

    <div class="achievements-wrapper mt-5">
        <div class="education-wrapper w-100 gap-4 px-5">        
        <div class="">
            <div class="clg-name"><i class="fa-solid fa-graduation-cap clg-grad-icon me-1"></i> VELLORE INSTITUTE OF TECHNOLOGY</div>
            <div class="divider"></div>
            <div class="d-md-flex d-block justify-content-between align-items-center w-100 mt-2">
                <div class="clg-degree px-2 py-1">B. Tech, Computer Science and Engineering</div>
                <div class="clg-grades px-2 py-1 position-relative">
                    8.37 CGPA
                    <img :src="lineImage" class="highlight-line" alt="">
                </div>
            </div>
            <div class="grad-year">
                2019 - 2023
            </div>
        </div>
        </div>
        <div v-for="certificate in certificates" :key="certificate.id" class="certificates p-3">
            <div class="logo-section d-flex gap-3 justify-content-start align-items-center">
                <div>
                <img :src="getLogoPath(certificate.logo)" alt="">
                </div>
                <div>
                    <div class="ach-title">{{ certificate.title }}</div>
                    <a :href="certificate.link" target="_blank" class="ext-link">View Certificate <i class="ms-1 fa-solid fa-arrow-up-right-from-square"></i></a>
                </div>
                

            </div>
            
        </div>
    </div>
</template>

<script>
// import {defineVueComponent} from 'vue';
import educationCap from '@/assets/svg/educationCap.svg';
import highlightLine from '@/assets/svg/highlight-line.svg'

export default {
    name: "AchievementSection",
    data() {
        return {
            graduationCap: educationCap,
            lineImage: highlightLine,
            certificates: [
                {
                    id: 0,
                    title: 'Microsoft Certified: Azure Fundamentals',
                    link: 'https://www.credly.com/badges/888a04e2-b07d-4893-8999-3dd914096252/linked_in_profile',
                    logo: 'microsoft'
                },
                {
                    id: 1,
                    title: 'Data Visualization and Communication with Tableau',
                    link: 'https://www.coursera.org/account/accomplishments/certificate/CHF66X9VT85S',
                    logo: 'coursera'
                },
                {
                    id: 2,
                    title: 'Introduction to Data, Signal, and Image Analysis with MATLAB',
                    link: 'https://www.coursera.org/account/accomplishments/certificate/85ZEJYJNWH6N',
                    logo: 'coursera'
                },
                {
                    id: 3,
                    title: 'Introduction to Packet Tracer',
                    link: 'https://www.credly.com/badges/2f7f1757-5ec9-481e-bcd1-572d540b46cd/linked_in_profile',
                    logo: 'cisco'
                },
            ]
        }
    },
    methods: {
        getLogoPath(logo) {
            return require(`@/assets/svg/company/${logo}.svg`);
        }
    }
    
}

</script>