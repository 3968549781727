import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.js'
import store from './store'
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { VTextField, VRow, VCol, VTextarea } from 'vuetify/components';


const vuetify = createVuetify({
    components: {
      VTextField,
      VTextarea,
      VRow,
      VCol,
    },
  });

createApp(App).use(store).use(vuetify).use(router).mount('#app')
