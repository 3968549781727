<template>
    <section class="home-section position-relative mt-3 mt-md-5 d-flex flex-column justify-content-between w-100">
        <svg-content />
            <!-- Location and social media platform -->
            <div class="d-flex justify-content-between align-items-center">
                <!-- Location -->
                <div class="location d-flex gap-3 justify-content-start align-items-center">
                    <i class="fa-solid fa-location-dot"></i>
                    <div>Bengaluru, India</div>
                </div> 

                <!-- Social media -->
                <div class="social-media d-flex justify-content-center gap-3 gap-md-5 align-items-center">
                    <a href="https://www.linkedin.com/in/jay-prajapati-614180191/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                    <a href="https://github.com/jayyprajapati" target="_blank"><i class="fa-brands fa-github"></i></a>
                    <a href="https://www.instagram.com/jayy.prajapati" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                </div>
            </div>

            <!-- Developer Intro -->
            <div class="dev-intro  d-flex flex-column gap-md-5 gap-4 justify-content-center align-items-center">
                <!-- Role Intro -->
                <div class="d-flex justify-content-center align-items-center">
                    <div class="prof-role d-flex gap-3 justify-content-center align-items-center">
                        <i :class="profIcon" class="fa-solid"></i>
                        <div ref="profContainer">{{prof}}.</div>
                        <img :src="highlightLine" class="highlight-line" alt="">
                    </div>
                </div>

                <!-- Name -->
                <div class="name">
                    {{ name }}
                </div>

                <div class="button-container d-flex flex-row gap-2 gap-md-4 justify-content-center align-items-center">
                    <a href="https://drive.google.com/file/d/1veBYrX8d0_rQ3ySbxBVuApG1HU1H6uG5/view?usp=sharing" target="_blank" class="resume-btn py-1 px-3">Resume <i class="ms-1 fa-solid fa-download"></i></a>
                    <button class="connect-btn py-1 px-4" @click.prevent="toggleModal">Connect <i class="ms-1 fa-regular fa-envelope"></i></button>
                </div>

            </div>

            <!-- Explore more -->

            <div class="explore-more position-relative mb-md-5 mb-2 d-flex gap-3 flex-column justify-content-start align-content-center">
                <i class="fa-regular fa-circle-question"></i>
                <div>
                    Explore more
                </div>
                <!-- Home section divider -->
                <object :data="homeDivider" type="image/svg+xml" class="home-divider-svg" />
                <!-- <img :src="downArrow" class="down-arrow-svg" alt=""> -->
            </div>
    </section>

    <!-- Modal -->
    <div class="resume-modal-overlay" v-if="isContactMeOpen">
        <div class="resume-modal p-4">
            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="resume-title">CONTACT ME</div>
                    <i class="fa-solid fa-circle-xmark cursor-pointer" @click="toggleModal"></i>
                </div>
                
                <div class="resume-sub-title">Let's discuss and work together to make something exciting... </div>
            </div>

            <form @submit.stop.prevent="handleSubmit">
                <div class="d-md-flex gap-3">
                    <v-text-field label="Full Name" variant="outlined" v-model="$v.form.name.$model"  :error-messages="nameErrors"></v-text-field>
                    <v-text-field label="Email Address" variant="outlined" v-model="$v.form.email.$model" :error-messages="emailErrors"></v-text-field>
                </div>
                

            <v-text-field label="Subject" variant="outlined" v-model="$v.form.subject.$model" :error-messages="subjectErrors" ></v-text-field>
            <v-textarea label="Message" variant="outlined" v-model="$v.form.message.$model" :error-messages="messageErrors"></v-textarea>

            <div>
                <div v-if="loading">
                    <svg class="loading" viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                </div>
            <button v-else type="submit" class="submit-btn px-3 py-1">Send <i class="ms-1 fa-solid fa-arrow-right-long"></i></button>
            </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import highlightLine from '@/assets/svg/highlight-line.svg';
import SvgContent from './SvgContent.vue';
import homeDivider from '@/assets/svg/home-divider.svg'
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';

const form = ref({
  name: '',
  email: '',
  subject: '',
  message: ''
});

const rules = {
  form: {
    name: { required, minLength: minLength(3) },
    email: { required, email },
    subject: {required, minLength: minLength(3)},
    message: {required, minLength: minLength(3)}
  },
};
// Initialize Vuelidate
const $v = useVuelidate(rules, { form });



const name = ref('JAY PRAJAPATI');
const prof = ref('Frontend Developer');
const profIcon = ref('fa-code');
const loading = ref(false)

// const nameRules = computed(() => [
//   () => $v.value.form.name.required || 'Name is required.',
//   () => $v.value.form.name.minLength || 'Name must be at least 3 characters.',
// ]);

// const emailRules = computed(() => [
//   () => !$v.value.form.email.$dirty || $v.value.form.email.required || 'Email is required.',
//   () => !$v.value.form.email.$dirty || $v.value.form.email.email || 'Must be a valid email.',
// ]);

const nameErrors = computed(() => {
//   $v.value.form.name.$touch();
  const errors = [];
        if (!$v.value.form.name.$dirty) return errors;
        $v.value.form.name.required.$invalid && errors.push('Name is required!');
        $v.value.form.name.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});
const emailErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.email.$dirty) return errors;
        $v.value.form.email.email.$invalid && errors.push('Invalid Email!');
        $v.value.form.email.required.$invalid && errors.push('Email is required!');
        return errors;
});

const subjectErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.subject.$dirty) return errors;
        $v.value.form.subject.required.$invalid && errors.push('Subject is required!');
        $v.value.form.subject.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});

const messageErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.message.$dirty) return errors;
        $v.value.form.message.required.$invalid && errors.push('Message is required!');
        $v.value.form.message.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});




const roles = ["Frontend Developer", "UI/UX Designer", "Freelancer", "Full-stack Developer"]
const icons = ["fa-code", "fa-bezier-curve", "fa-handshake", "fa-database"]
const profContainer = ref(null)
let currentIndex = 1;
const isContactMeOpen = ref(false)


function toggleModal() {
    isContactMeOpen.value = !isContactMeOpen.value
}


async function handleSubmit() {
    $v.value.$touch();

    if (!$v.value.$invalid) {
    // Form is valid, proceed with submission
    console.log('Form Submitted:', form.value);
    loading.value = true
    const formData = {
        name: form.value.name,
        email: form.value.email,
        subject: form.value.subject,
        message: form.value.message
    }
    console.log(formData);
    
    const resp = await fetch('https://email-service-drab-six.vercel.app/submit-form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    })

    console.log(resp)
    if(resp.ok) console.log("Form submitted successfully!");
    else console.log('Oops something went wrong!');
    loading.value = false;
  } else {
    // Handle validation errors
    console.log('Validation Errors:', $v.value.$errors);
  }

    
    
    
}
function changeText() {
    prof.value= roles[currentIndex];
    profIcon.value = icons[currentIndex];
    currentIndex = (currentIndex + 1) % roles.length;
}

    setInterval(changeText, 3000);
</script>